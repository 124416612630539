<template>
    <div>
        <div class="crumbs">
            <Breadcrumb v-bind:menuList="menuList"></Breadcrumb>
        </div>
        <div class="container">
            <el-form :inline="true" class="demo-form-inline">
                <el-form-item label="等级名称:">
                    <el-input class="s-input" v-model="queryParams.name" placeholder="请输入等级名称" clearable></el-input>
                </el-form-item>
                <!-- <el-form-item label="是否包邮:">
                    <el-select v-model="queryParams.shipping" placeholder="请选择是否包邮" clearable @change="changeQuery">
                        <el-option label="否" :value="1"></el-option>
                        <el-option label="是" :value="2"></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="是否可以开通店铺:">
                    <el-select v-model="queryParams.shop" placeholder="请选择是否可以开通店铺" clearable @change="changeQuery">
                        <el-option label="否" :value="1"></el-option>
                        <el-option label="是" :value="2"></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="状态:">
                    <el-select v-model="queryParams.state" placeholder="请选择状态" clearable @change="changeQuery">
                        <el-option label="下线" :value="1"></el-option>
                        <el-option label="上线" :value="2"></el-option>
                    </el-select>
                </el-form-item> -->

                <div style="float: right">
                    <el-button type="primary" @click="changeQuery()">搜索</el-button>
                </div>
            </el-form>
            <div class="action">
                <el-button type="primary" @click="add()">新增</el-button>
            </div>
            <!-- 表格内容 -->
            <el-table v-loading="loading" element-loading-text="数据加载中" stripe border :data="pageInfo.list">
                <el-table-column label="id" prop="id" width="100" align="center"></el-table-column>
                <el-table-column label="等级名称" prop="name" width="200" align="center"></el-table-column>
                <el-table-column label="级别" prop="level" width="150" align="center"></el-table-column>
                <el-table-column label="溢价比例" prop="consumption" width="200" align="center"></el-table-column>
                <el-table-column label="售后秒退" prop="customer" width="200" align="center">
                    <template slot-scope="scope">
                        <el-tag type="primary" v-if="scope.row.customer === 1">否</el-tag>
                        <el-tag type="success" v-if="scope.row.customer === 2">是</el-tag>
                    </template>
                </el-table-column>
                <el-table-column label="是否包邮" prop="shipping" width="200" align="center">
                    <template slot-scope="scope">
                        <el-tag type="primary" v-if="scope.row.shipping === 1">否</el-tag>
                        <el-tag type="success" v-if="scope.row.shipping === 2">是</el-tag>
                    </template>
                </el-table-column>
                <el-table-column label="关联分销渠道" prop="channel" width="200" align="center">
                    <template slot-scope="scope">
                        <el-tag type="success"  v-if="scope.row.channel" >{{ scope.row.channel.name }}</el-tag>
                        <el-tag type="warning" v-if="scope.row.channel === null">未关联</el-tag>
                    </template>
                </el-table-column>
                <el-table-column label="是否可以开通店铺" prop="shop" align="center">
                    <template slot-scope="scope">
                        <el-tag type="primary" v-if="scope.row.shop === 1">否</el-tag>
                        <el-tag type="success" v-if="scope.row.shop === 2">是</el-tag>
                    </template>
                </el-table-column>
                <el-table-column label="状态" prop="state" align="center">
                    <template slot-scope="scope">
                        <el-tag type="primary" v-if="scope.row.state === 1">下线</el-tag>
                        <el-tag type="success" v-if="scope.row.state === 2">上线</el-tag>
                    </template>
                </el-table-column>
                <el-table-column label="操作" align="center" fixed="right">
                    <template slot-scope="scope">
                        <el-button type="text" icon="el-icon-edit" @click="edit(scope.$index, scope.row)">编辑</el-button>
                        <el-button v-if="scope.row.state === 2" type="text" class="red" @click="state(scope.row, 1)">下线
                        </el-button>
                        <el-button v-if="scope.row.state === 1" type="text" class="green" @click="state(scope.row, 2)">
                            上线</el-button>
                    </template>
                </el-table-column>
            </el-table>
            <!-- 上线、下线提示框 -->
            <el-dialog title="提示" :visible.sync="confirmVisible" width="300px" center>
                <div class="del-dialog-cnt">{{ confirmContent }}</div>
                <span slot="footer" class="dialog-footer">
                    <el-button @click="confirmVisible = false">取 消</el-button>
                    <el-button type="primary" @click="stateData">确 定</el-button>
                </span>
            </el-dialog>
            <!-- 分页 -->
            <Pagination v-bind:pageInfo="pageInfo" v-on:refreshPageSize="refreshPageSize" v-on:refreshPageNum="refreshPageNum" />
        </div>
        <!-- 新增 编辑 -->
        <el-dialog :title="title" :visible.sync="addVisible" width="600px" class="dialog_box" v-if="addVisible">
            <el-form class="form-box" ref="form" v-bind:rules="rules" v-bind:model="form" v-loading.body="loading" label-position="left" label-suffix=":" label-width="150px" size="large" element-loading-text="上传中" style="margin-left: 30px;">
                <el-form-item label="等级名称" prop="name">
                    <el-input v-model="form.name" placeholder="请输入用户等级名称"></el-input>
                </el-form-item>
                <el-form-item label="等级" prop="level">
                    <el-input v-model="form.level" placeholder="请输入用户等级"></el-input>
                </el-form-item>
                <el-form-item label="关联分销渠道" prop="shipping">
                    <el-select  v-model="form.channel_id" placeholder="请选择关联分销渠道" class="handle-select mr10"  >
                        <el-option v-for="items in channelList" :key="items.id" :label="items.name" :value="items.id">
                        </el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="溢价比例" prop="consumption">
                    <el-input v-model="form.consumption" placeholder="请输入用户溢价比例"></el-input>
                </el-form-item>
                <el-form-item label="售后秒退" prop="customer">
                    <el-radio-group v-model="form.customer">
                        <el-radio :label="2">是</el-radio>
                        <el-radio :label="1">否</el-radio>
                    </el-radio-group>
                </el-form-item>
                <el-form-item label="是否包邮" prop="shipping">
                    <el-radio-group v-model="form.shipping">
                        <el-radio :label="2">是</el-radio>
                        <el-radio :label="1">否</el-radio>
                    </el-radio-group>
                </el-form-item>
                <el-form-item label="是否可以开通店铺" prop="shop">
                    <el-radio-group v-model="form.shop">
                        <el-radio :label="2">是</el-radio>
                        <el-radio :label="1">否</el-radio>
                    </el-radio-group>
                </el-form-item>
                <el-form-item label="使用价" prop="useprice">
                    <el-radio-group v-model="form.useprice">
                        <el-radio :label="0">销售价</el-radio>
                        <el-radio :label="1">分销价</el-radio>
                    </el-radio-group>
                </el-form-item>
                <el-form-item label="状态" prop="state">
                    <el-radio-group v-model="form.state">
                        <el-radio :label="2">上线</el-radio>
                        <el-radio :label="1">下线</el-radio>
                    </el-radio-group>
                </el-form-item>
            </el-form>
            <div slot="footer" class="dialog-footer">
                <el-button @click="addVisible = false">取 消</el-button>
                <el-button type="primary" @click="saveData">提 交</el-button>
            </div>
        </el-dialog>

        <!-- 上线、下线提示框 -->
        <el-dialog title="提示" :visible.sync="confirmVisible" width="300px" center>
            <div class="del-dialog-cnt">{{ confirmContent }}</div>
            <span slot="footer" class="dialog-footer">
                <el-button @click="confirmVisible = false">取 消</el-button>
                <el-button type="primary" @click="stateData">确 定</el-button>
            </span>
        </el-dialog>
    </div>
</template>
<script>
import Breadcrumb from '@/components/Breadcrumb'
import Pagination from '@/components/Pagination'
import { request } from '@/common/request'
import { deal } from '@/common/main'
export default {
    name: 'StoreInfo',
    components: {
        Breadcrumb,
        Pagination
    },
    data() {
        return {
            menuList: ['客户', '客户等级'],
            loading: false,
            confirmVisible: false,
            confirmContent: '',
            queryParams: {
                page: 1,
                pagesize: 10,
            },
            pageInfo: {},
            channelList:[],
            addVisible: false,
            addVisibles: false,
            title: '添加客户等级',
            rules: {
                name: [{
                    required: true,
                    message: '等级名称不能为空',
                    trigger: 'blur'
                }],
                level: [{
                    required: true,
                    message: '等级不能为空',
                    trigger: 'blur'
                },
                {
                    pattern: '^[0-9]*$',
                    message: "请输入数字"
                }],
                channel_id: [{
                    required: true,
                    message: '渠道不能为空',
                    trigger: 'blur'
                }],
                consumption: [{
                    required: true,
                    message: '溢价比例不能为空',
                    trigger: 'blur'
                }],
                customer: [{
                    required: true,
                    message: '是否秒退不能为空',
                    trigger: ['blur', 'change']
                }],
                shipping: [{
                    required: true,
                    message: '是否包邮不能为空',
                    trigger: ['blur', 'change']
                }],
                shop: [{
                    required: true,
                    message: '是否可以开通店铺不能为空',
                    trigger: ['blur', 'change']
                }],
                state: [{
                    required: true,
                    message: '状态不能为空',
                    trigger: ['blur', 'change']
                }],
                useprice: [{
                    required: true,
                    message: '使用价不能为空',
                    trigger: ['blur', 'change']
                }]

            },
            pickerOptions: {
                disabledDate(time) {
                    return time.getTime() < Date.now() - 8.64e7;
                }
            },

            mode: 'add',
            form: {
                channel_id:0,
            },
        }
    },
    created() {
        // this.hearder.token = localStorage.getItem('token');
        // this.hearder.platform = 'admin';
        this.loadData();
    },
    methods: {
        loadData() {
            this.loading = true;
            //用户等级列表
            request.get('/account/grade/list', this.queryParams).then(ret => {
                if (ret.code == 1) {
                    this.loading = false;
                    this.pageInfo = ret.data;
                    this.channelList = ret.data.channelList;
                    console.log(22222, this.pageInfo)
                    this.pageInfo.list = ret.data.list;
                    this.pageInfo.list.map(function (val) {
                    })
                } else {
                    this.$message.error(ret.msg);
                }
            });
        },
        //分页
        changeQuery() {
            this.queryParams.page = 1
            this.loadData()
        },
        refreshPageSize(val) {
            this.queryParams.pagesize = val
            this.loadData()
        },
        refreshPageNum(val) {
            this.queryParams.page = val
            this.loadData()
        },
        add(id, index) {
            this.mode = 'add';
            this.title = '新增客户等级';
            this.form = { num: 1, countType: 1, ty: 1 }
            this.addVisible = true
            this.form = {
                state: 2,
                customer: 2,
                shipping: 2,
                shop: 2,
                useprice: 0,
            };
            if (this.$refs.form) {
                this.$refs.form.clearValidate()
            }
            // this.addVisible = true
        },
        //状态
        state(row, state) {
            if (state == 2) {
                this.confirmContent = '确定是否上线?';
            } else {
                this.confirmContent = '确定是否下线?';
            }
            this.form = Object.assign({}, row);
            this.form.state = state;
            this.form.ctime = deal.timestamp(this.form.ctime);
            this.form.etime = deal.timestamp(this.form.etime);
            this.confirmVisible = true;
        },
        //编辑
        edit(index, row) {
            this.mode = 'edit';
            this.title = '编辑客户等级';
            const item = this.pageInfo.list[index];
            let time = [item.stime, item.etime]
            //处理时间
            this.form = Object.assign({}, item);
            this.$set(this.form, 'time', time);
            this.form.level = this.form.level + ''
            this.form.consumption = this.form.consumption + ''
            this.addVisible = true;
            if (this.$refs.form) {
                this.$refs.form.clearValidate()
            }
        },
        delete(id, index) {

        },
        changeCount(num) {
            this.form.num = num
        },
        changeDatePicker: function () {
            let vue = this
            if (vue.pickerDatad != null) {
                vue.form.stime = vue.pickerDatad[0]
                vue.form.etime = vue.pickerDatad[1]
            } else {
                vue.form.stime = null
                vue.form.etime = null
            }
        },
        //增加编辑
        saveData() {
            if (this.$refs.form) {
                this.$refs.form.validate(valid => {
                    if (valid) {
                        //   this.form.num = this.form.countType == 1 ? 1 : this.form.num;
                        // this.form.brand_ids = this.form.brand_id;
                        var url = this.mode == 'add' ? '/account/grade/add' : '/account/grade/edit';
                        request.post(url, this.form).then(ret => {
                            if (ret.code == 1) {
                                this.addVisible = false;
                                this.addVisibles = false;
                                this.loadData();
                                this.$message.success((this.mode == 'add' ? '新增' : '编辑') +
                                    '成功');
                                this.$refs.form.clearValidate()
                            } else {
                                this.$message.error(ret.msg);
                            }
                        });
                    } else {
                        this.$message.error('请补全信息');
                        return false;
                    }
                });
            };
        },
        // 确定下线
        stateData() {
            request.post('/account/grade/edit', this.form).then(ret => {
                if (ret.code == 1) {
                    this.$message.success('操作成功');
                    this.loadData();
                    this.confirmVisible = false;
                } else {
                    this.$message.error(ret.msg);
                }
            });
        }
    }
}

</script>

<style scoped>
.red {
    color: #ff0000;
}

.green {
    color: green;
}
/* 对话框样式 */

.dialog_box >>> .el-dialog__body {
    padding: 20px 30px 20px 60px;
}

.dialog_box >>> .el-dialog__header {
    padding: 30px 30px 10px;
}

.dialog_box .el-form-item--small.el-form-item {
    margin-bottom: 20px;
}
</style>
